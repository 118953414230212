<template>
  <div class="type-management-container">
    <vab-query-form>
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.prevent>
          <el-form-item label="请输入用户名">
            <el-input v-model="queryForm.name" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button :icon="Search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column label="证件类型" prop="title" show-overflow-tooltip>
        <template #default="{ row }">
          {{ handleFilter(row.idType) }}
        </template>
      </el-table-column>
      <el-table-column label="手机号" prop="phone" show-overflow-tooltip />
      <el-table-column label="真实姓名" prop="name" show-overflow-tooltip />
      <el-table-column label="证件号码" prop="idNo" show-overflow-tooltip />
      <el-table-column label="证件图片" prop="title" show-overflow-tooltip>
        <template #default="{ row }">
          <el-image v-for="(item, index) in row.fileUrls" :src="item" :key="index" :zoom-rate="1.2" :preview-src-list="row.fileUrls"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="审核状态" show-overflow-tooltip>
        <template #default="{ row }">
          <el-tag v-if="row.status == 'PENDING'" type="warning">待审核</el-tag>
          <el-tag v-if="row.status == 'PASS'" type="warning">已实名</el-tag>
          <el-tag v-if="row.status == 'REFUSE'" type="warning">已拒绝</el-tag>
          <!-- <el-select v-model="row.status" @change="statusChange(row)">
            <template v-for="(item, index) in statusList" :key="index">
              <el-option :value="item.key" :label="item.label"></el-option>
            </template>
          </el-select> -->
        </template>
      </el-table-column>
      <el-table-column label="申请时间" prop="title" show-overflow-tooltip>
        <template #default="{ row }">
          <span>{{ handleDate(row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" show-overflow-tooltip width="185">
        <template #default="{ row }">
          <el-button type="primary" v-if="row.status == 'PENDING'" @click="handleEdit(row)">
            审核
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination background :current-page="queryForm.pageNumber" :layout="layout" :page-size="queryForm.pageSize" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    <edit ref="editRef" @fetch-data="fetchData" />
  </div>
</template>

<script>
import {
  defineComponent,
  defineAsyncComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
  onActivated,
  onDeactivated,
} from 'vue'

import { Delete, Plus, Search } from '@element-plus/icons'
import { getRealNameAuditList } from '@/api/user'
import { parseTime } from '@/utils/index'
export default defineComponent({
  name: 'contentManage',
  components: {
    Edit: defineAsyncComponent(() => import('./editHost')),
  },
  setup() {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      queryForm: {
        name: '',
        pageNum: 1,
        pageSize: 20,
      },
      statusList: [
        {
          label: '待审核',
          key: 'PENDING_APPROVAL_STATUS',
        },
        {
          label: '审核通过',
          key: 'PASS',
        },
      ],
      total: 0,
      listLoading: false,
      list: [],
      layout: 'prev, pager, next,total,sizes',
      editRef: null,
      categoryList: [],
    })

    const handleEdit = (row) => {
      if (row.uid) {
        state.editRef.showEdit(row)
      }
    }
    const handleDelete = (row) => {
      if (row.id) {
        proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg } = await deleteArticle({ id: row.id })
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          await fetchData()
        })
      }
    }

    const queryData = () => {
      fetchData()
    }

    const fetchData = async () => {
      state.listLoading = true
      const { data } = await getRealNameAuditList(state.queryForm)
      state.list = data.data
      state.total = data.total
      state.listLoading = false
    }

    const handleSizeChange = (val) => {
      state.queryForm.pageSize = val
      fetchData()
    }
    const handleCurrentChange = (val) => {
      state.queryForm.pageNum = val
      fetchData()
    }

    const handleFilter = (type) => {
      let idNumerTypeList = [
        {
          name: '身份证',
          alias: 'idCard',
          desc: '中华人民共和国居民身份证',
          key: 'CERTIFICATES_TYPE_1',
        },
        {
          name: '回乡证',
          alias: 'homeCard',
          desc: '港澳居民来往内地通行证',
          descImg:
            '请上传“回乡证”正反面清晰照片（JPG/PNG，≤5MB），确保关键信息可见。',
          key: 'CERTIFICATES_TYPE_2',
        },
        {
          name: '台胞证',
          alias: 'homeCard',
          desc: '台湾居民来往大陆通行证',
          descImg:
            '请上传“台胞证”正反面清晰照片（JPG/PNG，≤5MB），确保关键信息可见。',
          key: 'CERTIFICATES_TYPE_3',
        },
        {
          name: '港澳台居民居住证',
          alias: 'homeCard',
          desc: '港澳台居民居住证',
          descImg:
            '请上传“居住证”正反面清晰照片（JPG/PNG，≤5MB），确保关键信息可见。',
          key: 'CERTIFICATES_TYPE_8',
        },
        {
          name: '外国护照',
          alias: 'homeCard',
          desc: '外国护照',
          descImg:
            '请上传“护照”正反面清晰照片（JPG/PNG，≤5MB），确保关键信息可见。',
          key: 'CERTIFICATES_TYPE_5',
        },
        {
          name: '外国人永久居住证',
          alias: 'homeCard',
          desc: '外国人永久居住证',
          descImg:
            '请上传“护照”正反面清晰照片（JPG/PNG，≤5MB），确保关键信息可见。',
          key: 'CERTIFICATES_TYPE_4',
        },
        {
          name: '其他',
          alias: 'homeCard',
          desc: '其他',
          descImg: '请上传清晰照片(JPG/PNG,≤5MB),确保关键信息可见。',
          key: 'CERTIFICATES_TYPE_99',
        },
      ]
      return idNumerTypeList.filter((v) => v.key == type)[0].name
    }

    onActivated(() => {
      fetchData()
      console.log('我执行了')
    })

    const statusChange = async (row) => {
      console.log(row)
      try {
        const data = await upDateAdvertStatus(row.advertisingCode, row.status)
        fetchData()
      } catch (err) {
        fetchData()
      }
    }

    const handleDate = (val) => {
      return parseTime(val)
    }

    return {
      ...toRefs(state),
      handleEdit,
      queryData,
      fetchData,
      handleSizeChange,
      handleCurrentChange,
      handleDelete,
      Delete,
      Search,
      Plus,
      statusChange,
      handleFilter,
      handleDate,
    }
  },
})
</script>
<style lang="scss" scoped></style>
